<template>
  <div v-if="mounted">
    <CCard>
      <CCardBody>
        <div>
          <h4 class="card-title mb-0">Customer Order Analysis</h4>
          <div class="small text-muted">Detailed customer ordering patterns and behaviors</div>
        </div>

        <!-- Filters -->
        <CCard class="filters my-3" accent-color="warning">
          <CCardBody class="p-2">
            <div class="grid">
              <v-select
                class="v-select-filter"
                v-model="filters.year"
                :options="years"
                :searchable="false"
                placeholder="Select year..."
                @input="inputFilter"
              />

              <v-select
                class="v-select-filter"
                v-model="filters.orderChannel"
                :options="orderChannels"
                :searchable="false"
                placeholder="Select order channel..."
                @input="inputFilter"
              />

              <CInput
                class="mb-0 search"
                type="search"
                placeholder="Search for customer.."
                v-model="filters.search"
                @input="searchFilter"
              />

              <div class="form-check mt-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="filters.multipleOrders"
                  id="multipleOrders"
                  @change="inputFilter"
                >
                <label class="form-check-label" for="multipleOrders">
                  Only multiple orders (per customer)
                </label>
              </div>
            </div>
          </CCardBody>
        </CCard>

        <!-- Summary Stats -->
        <div class="row mb-4 stats-cards">
          <div class="col-sm-6 col-lg-3">
            <CWidgetDropdown
              color="primary"
              :header="`${stats.totalCustomers}`"
              text="Total Customers"
            />
          </div>
          <div class="col-sm-6 col-lg-3">
            <CWidgetDropdown
              color="info"
              :header="`${stats.totalOrders}`"
              text="Total Orders"
            />
          </div>
          <div class="col-sm-6 col-lg-3">
            <CWidgetDropdown
              color="warning"
              :header="$options.filters.toCurrency(stats.avgOrderAmount, 'remove_thousand_separator')"
              text="Average Order Amount"
            />
          </div>
          <div class="col-sm-6 col-lg-3">
            <CWidgetDropdown
              color="success"
              :header="`${stats.avgOrderCount}`"
              text="Orders per Customer"
            />
          </div>
        </div>

        <!-- Main Analysis Table -->
        <div class="table-responsive">
          <CDataTable
            striped
            hover
            :items="customers"
            :fields="[
              { key: 'customerName', label: 'Customer Name', _style: 'min-width:200px' },
              { key: 'customerEmail', label: 'Email', _style: 'min-width:250px' },
              { key: 'year', label: 'Year' },
              { key: 'orderCount', label: 'Orders' },
              { key: 'averageAmount', label: 'Avg Amount (€)' },
              { key: 'actions', label: '', _style: 'width:1%' }
            ]"
            :sorter="{ external: true, resetable: true }"
            :itemsPerPageSelect="{
              external: true,
              values: [5, 15, 25, 50, 100, 250, 500],
            }"
            :items-per-page.sync="itemsPerPage"
            :loading="loading"
            :noItemsView="{
              noResults: 'No filtering results are available!',
              noItems: 'No items found!',
            }"
            @update:sorter-value="sorterValue"
            @pagination-change="paginationChange"
          >
            <template #year="{item}">
              <td>
                {{ item.year || 'All Years' }}
              </td>
            </template>

            <template #averageAmount="{item}">
              <td>
                {{ item.averageAmount | toCurrency('remove_thousand_separator') }}
              </td>
            </template>

            <template #actions="{item}">
              <td>
                <CButton
                  color="info"
                  size="sm"
                  shape="pill"
                  @click="showCustomerDetails(item)"
                >
                  <CIcon name="cil-zoom-in" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </div>

        <!-- Actions -->
        <CCard class="actions sticky-bottom">
          <CCardBody class="p-2">
            <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
              <div>
                <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
              </div>

              <div v-if="pages > 1" class="ml-auto">
                <CPagination align="center" :dots='false' :pages="pages" :active-page.sync="activePage" />
              </div>
            </div>
          </CCardBody>
        </CCard>

        <!-- Customer Details Modal -->
        <CModal
          :show.sync="showModal"
          title="Customer Order Details"
          size="lg"
        >
          <div v-if="selectedCustomer">
            <div class="customer-info mb-3">
              <h5>{{ selectedCustomer.customerName }}</h5>
              <p class="text-muted">{{ selectedCustomer.customerEmail }}</p>
            </div>

            <div class="row mb-3">
              <div class="col-md-4">
                <div class="border rounded p-3 text-center">
                  <h6>Total Orders</h6>
                  <strong>{{ selectedCustomer.orderCount }}</strong>
                </div>
              </div>
              <div class="col-md-4">
                <div class="border rounded p-3 text-center">
                  <h6>Average Amount</h6>
                  <strong>{{ selectedCustomer.averageAmount | toCurrency('remove_thousand_separator') }}</strong>
                </div>
              </div>
              <div class="col-md-4">
                <div class="border rounded p-3 text-center">
                  <h6>Year</h6>
                  <strong>{{ selectedCustomer.year || 'All Years' }}</strong>
                </div>
              </div>
            </div>
          </div>

          <template #footer>
            <CButton
              color="secondary"
              @click="showModal = false"
            >
              Close
            </CButton>
          </template>
        </CModal>

        <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
      </CCardBody>
    </CCard>
  </div>
  <CSpinner v-else color="primary" />
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: 'CustomerAnalysis',
  data() {
    return {
      mounted: false,
      loading: false,
      customers: [],
      years: [],
      orderChannels: [
        { value: '0', label: 'MS' },
        { value: '1', label: 'SW' },
        { value: '2', label: 'APP' },
      ],
      filters: {
        year: null,
        orderChannel: null,
        multipleOrders: false,
        search: null,
      },
      stats: {
        totalCustomers: 0,
        totalOrders: 0,
        avgOrderAmount: 0,
        avgOrderCount: 0
      },
      showModal: false,
      selectedCustomer: null,

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },

  watch: {
    reloadParams() {
      if (this.queryPage != this.activePage)
        this.$router.push({ name: 'CustomerAnalysis', query: { page: this.activePage } });

      this.fetchData();
    }
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
  },

  created() {
    this.filters.year = new Date().getFullYear();
    this.initYear()
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.fetchData();
    }, 1000);
  },

  async mounted() {
    this.activePage = this.queryPage;

    await Promise.all([
      this.fetchData(),
    ])
    this.mounted = true;

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    };
  },

  methods: {
    initYear() {
      const currentYear = new Date().getFullYear();
      const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
      this.years = range(currentYear, 2017, -1);
    },

    async fetchData() {
      this.loading = true;
      var url = new URL(this.$backend.REPORTS.ORDERS.CUSTOMER_ANALYSIS),
        params = {
          'year': this.filters.year,
          'order-channel': this.filters.orderChannel?.value,
          'multiple-orders': this.filters.multipleOrders,
          'search': this.filters.search,

          'page': this.activePage,
          'itemsPerPage': this.itemsPerPage,
          'order-by': this.orderBy,
          'direction': this.direction,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      try {
        const response = await this.$axios.get(url)
        const { data, meta, stats } = response.data;

        // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
        if (meta.current_page > meta.last_page)
          this.activePage = 1;
        else {
          this.activePage = meta.current_page;
          this.pages = meta.last_page;
          this.total = meta.total;

          this.customers = data;
          this.stats = stats;
        }
      } catch (error) {
        this.$toast.error('Failed to fetch customer analysis data')
      }
      finally {
        this.loading = false
      }
    },

    showCustomerDetails(customer) {
      this.selectedCustomer = customer
      this.showModal = true
    },

    async inputFilter() {
      await this.fetchData();
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.fetchData();
    },
    paginationChange(value) {
      this.itemsPerPage = value;
      this.fetchData();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.fetchData();
    },
  }
}
</script>

<style>
.filters .grid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.search input {
  font-size: 1rem !important;
}

.stats-cards .card .card-body {
  padding-bottom: 20px !important;
}

.table th, .table td {
  vertical-align: middle;
}

.customer-info {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 1rem;
}

.customer-info h5 {
  margin-bottom: 0.25rem;
}

.customer-info p {
  margin-bottom: 0;
}
</style>
